/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Cera";
    src: local("Cera"), url("./assets/fonts/Cera.woff2") format("woff2");
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Cera;
  background: #a5f3fe;
}

body::-webkit-scrollbar {
  display: none;
}

.logo {
  width: 30%;
}

.coming-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image__baby {
  width: 800px;
}

@media (max-width: 700px) {
  .logo {
    width: 200px;
  }
}
